.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Mulish', sans-serif;
}

.qrcode__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
}

.qrcode__container--parent {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.qrcode__input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin-top: 20px;
}


.qrcode__input input {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  outline: none;
  resize: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
}

.qrcode__input button, 
.qrcode__download button {
  display: inline-block;
  padding: 7px;
  cursor: pointer;
  color: #fff;
  background-color: rgb(96, 96, 246);
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.qrcode__download {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.qrcode__download button {
  margin-top: 10px;
}